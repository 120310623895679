<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>设置交易密码</h6>
            </div>
            <div class="col-12 mb-4">
              
                <div v-if="tips != ''" class="alert alert-danger p-1 mt-2 d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-lightbulb" viewBox="0 0 16 16">
                        <path
                            d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                    </svg>
                    <span class="ml-1 mt-1">{{tips}}</span>
                </div>
              
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2"
                  style="width: 100px"
                >
                  <span>交易密码</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="transPassword"
                    class="form-control mr-sm-2 width-300"
                    maxlength="6"
                    placeholder="请输入6位数字交易密码"
                    type="password"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2 width-100"
                >
                  <span>确认交易密码</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="againPassword"
                    class="form-control mr-sm-2 width-300"
                    maxlength="6"
                    placeholder="请再次输入交易密码确认"
                    type="password"
                  />
                </div>
              </div>

              <div class="clearfix mt-3">
                <b-button
                  @click="modifyPassword"
                  class="width-300"
                  variant="success"
                  style="margin-left: 116px"
                  >确认提交</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "transpasswordsetting",
  data() {
    return {
      tips: "", //消息提示
      transPassword: "", //新密码
      againPassword: "", //确认新密码
    };
  },
  methods: {
    verify() {
        if (this.transPassword == "") {
            this.tips = "请输入交易密码";
            return false;
        }
        if (this.againPassword == "") {
            this.tips = "请输入确认交易密码";
            return false;
        } else {
          if(this.transPassword.length < 6 || !this.$test.number(this.transPassword)) {
              this.tips = "交易密码为6位数字，且区别于登录密码";
              return false;
          }
        }
        if (this.transPassword != this.againPassword) {
            this.tips = "确认交易密码与交易密码不一致，请重新输入";
            return false;
        }
        return true;
    },
    modifyPassword() {
        if(this.verify()) {
            this.$http .post(this.$api.user.setTradePassword, {
              transPassword: this.transPassword,
            }).then((res) => {
                if (res.code == 1) {
                  this.$model.toast("设置交易密码成功");
                  Object.assign(this.$data, this.$options.data());
                }
            });
        }
    },
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
